// Variables
@import "variables";

@import url('https://fonts.googleapis.com/css?family=Shrikhand|Source+Sans+Pro:300,400,700,900');
$font: 'Source Sans Pro', sans-serif;
$font-title: 'Shrikhand', cursive;

.hidden {
  display: none;
}
.pad { padding: $pad; }
.pad-left { padding-left: $pad; }
.pad-right { padding-right: $pad; }
.pad-top { padding-top: $pad !important; }
.pad-bot { padding-bottom: $pad !important; }

.small-pad { padding: $pad/2; }
.small-pad-left { padding-left: $pad/2; }
.small-pad-right { padding-right: $pad/2; }
.small-pad-top { padding-top:$pad/2; }
.small-pad-bot { padding-bottom: $pad/2; }

.mar { margin: $mar; }
.mar-left { margin-left: $mar; }
.mar-right { margin-right: $mar; }
.mar-top { margin-top: $mar; }
.mar-bot { margin-bottom: $mar; }

.vh {
  min-height: 100vh;
  margin-top:-50px;
  padding-top:50px;
}

/* Video Bits */
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* TO IMPORT TO LAYOUT.CSS*/
.small-mar-right { margin-right: $mar/2;  }
.small-mar-left {  margin-left: $mar/2;  }

.email2 {
  display: none;
}
body {
  font-family: $font;
  font-size: 1em;
  color: #FFF;
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;

  h1, h2, h3 {
    color: #FFF;
    font-family: $font-title;
    text-shadow: 2px 2px #000;
  }

}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
.blog-search {
  margin-right: 0 !important;
  margin-left: 0 !important;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

#bg-image{
  position: relative;
  width: 100%;
  background: #333;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  z-index: 1;
}
.page-contents {
  padding-top:100px;
  position: absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index: 999;
  height:100%;
  padding-bottom:40px;
  overflow-y: scroll;
}

.navbar {
  background-color: $black;
  .logo {
    height:40px;
    width:auto;
  }
  .navbar-nav {
    margin-right: $mar;
  }
  .nav-link {
    padding:$pad $pad/2;
    font-family: $font-title;
    color: #dedede;
    font-size: 18px;
  }
  .nav-link:hover {
    color: #FFF;
    text-decoration: none;
  }
}
footer {
  position: absolute;
  bottom:0px;
  width:100%;
  height:40px;
  background-color: $black;
  z-index: 999;
  ul {
    list-style: none;
    margin:0px;
    padding:0px;
    li {
      display: inline-block;
      a {
        color: #dedede;
        padding: $pad/2;
        display: block;
      }
      a:hover {
        color: #FFF;
        text-decoration: none;
      }
    }
  }
  small {
    line-height: 40px;
    color: #ccc;
  }
}

.home-social {
  padding:$pad;
  margin: -$mar $mar $mar $mar;
  text-align: center;
  color: #FFF;
  font-size: 1.4em;


  img {
    width:80%;
    margin:0 auto;
    position: relative;
    transition: all 0.5s ease;
  }
  img:hover {
    width:100%;

  }

}
.navbar-toggler {
  float: right;
  color: #FFF;
}
.sidemenu {
  margin:0px;
  padding:0px;
  list-style: none;
  li {
    .fa { opacity: 0;
    }
  }
  li:hover {
    .fa {
      opacity: 1;
    }
  }
  a {
    padding-left: $pad;
    color: #FFF;
    font-size: 2em;
    text-shadow: 2px 2px #000;
  }
  a:hover, a:active {
    text-decoration: none;
  }

}
.about-div {
  width:100%;
  min-height:400px;
  background-color: rgba(0,0,0,0.6);
  padding: $pad;
  visibility: hidden;
  position: absolute;
  border-radius: $pad;
}
.visible {
  visibility: visible !important;
}

/* Cards */
.card-default {
  border: 1px solid $black;
  .card-header {
    background-color: $pink;
    font-family: $font-title;
    font-size: 1.5em;
  }
  .card-block {
    color: $black;
  }
}

/* Buttons */
.btn-primary {
  background-color: $bluedark;
}
.btn-primary:hover {
  background-color: $bluelight;
}
a.btn:hover {
  text-decoration: none;
}

/* Hexagons */
.account-user{
  font-size: 45px;
  padding: 10px;
  position: absolute;
  top:-6px;
  left:24px;
}
.account-hexagon-link {
  color: #000000;
}
.account-hexagon-link:hover {
  color: #FFFFFF;
}
.account-hexagon {
  width: 100px;
  height: 55px;
  background: $pink;
  position: relative;
  text-align: center;
  transition: all 1s;
  margin:35px auto;
  display: block;
}
.account-hexagon:before {
  content: "";
  position: absolute;
  top: -25px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 25px solid $pink;
  transition: all 1s;
}
.account-hexagon:after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 25px solid $pink;
  transition: all 1s;
}
.account-hexagon-link:hover .account-hexagon {
  background-color: $pink;
}
.account-hexagon-link:hover .account-hexagon:before {
  border-bottom: 25px solid $pink;
}
.account-hexagon-link:hover .account-hexagon:after {
  border-top: 25px solid $pink;
}

/* WIDGET EDIT */
.widget-wrap {
  position: relative;
}
.widget-edit {
  display: none;
}
.widget-admin:hover  {
  display: block;
  border: 1px solid #000;
  min-height:55px;
}
.widget-admin:hover .widget-edit {
  display: inline-block;
  position: absolute;
  right:0px;
  padding: 10px;
  background-color: rgba(0,0,0,0.8);
}
.widget-admin:hover a {
  color: #FFF;
}

/* Logins */
.login-loading {
  padding:30px;
  text-align: center;
  display: none;
}
.login-success {
  text-align: center;
  display: none;
}

/* Alerts */
.alert ul {
  margin:0px;
  padding:0px;
  list-style: none;
}

/* Boxes */
.whitebox {
  background-color: rgba(255,255,255,0.7);
  border-radius: $pad;
  padding:$pad;
  color: $black;
}
.blackbox {
  background-color: rgba(0,0,0,0.7);
  border-radius: $pad;
  padding:$pad;
  color: #FFF;
  .fa-close {
    color: $pink !important;
  }
  .fa-close:hover {
    color: $white !important;
  }
}


/* Slick Generic */

.slick-arrow {
  position: absolute;
  top: 40%;
  border: 5px solid #FFFFFF;
  padding: 10px;
  color: #FFFFFF;
  z-index: 990;
  font-size: 28px;
}
ul.slick-slider {
  margin:0px;
  padding:0px;
  list-style: none;
}
